import * as React from "react";
import Helmet from "react-helmet";

const FavIcon: React.FunctionComponent = () => {
    return (
        <Helmet>
            <link rel="shortcut icon" href="/favicon.ico" type="image/ico" />
        </Helmet>
    );
};

export default FavIcon;
